import React, {Component   } from 'react';
import { Tabs, Tab, Grid, Cell, Card, CardTitle,CardActions,Button,CardMenu, List, ListItem, IconButtond, CardText, IconButton} from 'react-mdl';

const makelists = item => <ListItem>item</ListItem>



const Headings = (items) => {
    const headings = items.map((item, index)=>
      <li key={index}>{item}</li>)
    return <ListItem>{headings}</ListItem>
  }

class ProjectCards extends Component{
    render (){
        // var lists=this.props.items.map(title => {
        //     return <ListItem >{title}</ListItem>;}
        //   );
        
        return (
            <Card shadow={5} style = {{minWidth:450 , margin: 'auto'}}>
                        <CardTitle >
                            {this.props.title}
                        </CardTitle>
                        <CardText>
                          {this.props.description}
                            <p></p>
                            <p>Domain and Technologies</p>
                            <div>
                                <List>
                                    {Headings(this.props.items)}
                                </List>
                            </div>
                        </CardText>
                        <CardActions>
                        <Button colored>Github</Button>
                        <Button colored>LiveDemo</Button>
                        <Button colored>CodePen</Button>
                        </CardActions>
                        <CardMenu style ={{color: '#fff'}}>
                            <IconButton name ="share"/>
                        </CardMenu>
                    </Card>
        );
    }
}

export default ProjectCards;