/* eslint-disable jsx-a11y/alt-text */
import React, {Component   } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


class CarouselComponent extends Component {

render(){
    return (
        <div class="carousel-wrapper">
        <Carousel>
            <div>
                <img src="../../public/images/apigee.png" />
            </div>
            <div>
                <img src="../../public/images/aws.png" />
            </div>
            <div>
                <img src="https://www.google.com.au/url?sa=i&url=https%3A%2F%2Fmedium.com%2F%40victorsmelopoa%2Fan-introduction-to-elasticsearch-with-kibana-78071db3704&psig=AOvVaw2gNn840l6obZKhlAa5fO92&ust=1588687689664000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJiJof6wmukCFQAAAAAdAAAAABAJ" />
            </div>
        </Carousel>
    </div>
    );
}

}

export default CarouselComponent;