import React, {Component   } from 'react';

import {Grid, Cell, List, ListItem, ListItemContent} from 'react-mdl';
import CarouselComponent from './carousel.component.js';

class About extends Component{

    render(){
        return ( 
            <div> 
                <div>
                <Grid className="about-grid">
                    <Cell col={8} className="about-intro">
                        <ListItem>
                    <ListItemContent style={{fontSize:'30px', fontFamily:'Anton'}} >
                           <h3>Joseph Johney</h3>

                           <p style = {{ width :'75%', margin:'auto', paddingTop:'1em'}}>

                               I am a Designer, a special one.
                               </p>             
                                        
                                    </ListItemContent>
                                </ListItem>
                    </Cell>
                        <Cell col={3}>
                        <List>
                                <ListItem >
                                    <ListItemContent style={{fontSize:'30px', fontFamily:'Anton'}} >
                                        <i className="fa fa-phone-square" aria-hidden="true"/>
                                        +61 439473163
                                    </ListItemContent>
                                </ListItem>

                                <ListItem >
                                    <ListItemContent style={{fontSize:'30px', fontFamily:'Anton'}} >
                                        <i className="fa fa-envelope" aria-hidden="true"/>
                                        josephjohney16@gmail.com
                                    </ListItemContent>
                                </ListItem>

                                <ListItem >
                                    <ListItemContent style={{fontSize:'30px', fontFamily:'Anton'}} >
                                        <i className="fa fa-fax" aria-hidden="true"/>
                                        +61 439473163
                                    </ListItemContent>
                                </ListItem>

                                <ListItem >
                                    <ListItemContent style={{fontSize:'30px', fontFamily:'Anton'}} >
                                        <i className="fa fa-skype" aria-hidden="true"/>
                                        josephjohney@outlook.com
                                    </ListItemContent>

                                </ListItem>
                            </List>

                        </Cell>

                        <div>
                        <Cell col={8} className="carousels.tools">
                        <CarouselComponent></CarouselComponent>

                    </Cell>
                    </div>
                </Grid>

                \
                </div>
            </div>
        )
    }
}

export default About;
