import React, { Component } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Cell,
  Card,
  CardTitle,
  CardActions,
  Button,
  CardMenu,
  List,
  ListItem,
  IconButtond,
  CardText,
  IconButton,
} from "react-mdl";
import ProjectCards from "./ProjectCards";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = { activeTab: 0 };
  }
  toggleCategories() {
    if (this.state.activeTab === 0) {
      return (
        <div className="projects-grid">

          <ProjectCards
            title="  Cloud Migration and Infrastructure Design"
            description="Design Cloud migration strategy for a major Australian Bank to move into Google Cloud Platform from its dedicated datacenters."
            items = {['Banking', 'Infra Design','Security Architecture', 'Infrastructure as Code']}
          ></ProjectCards>
          <ProjectCards
            title="Fraud and Cyber Crime - Solution Consultant"
            description="Designing the various solutions incorporated in a leading Australian Bank to detect and remediate Fraudulant events occuring in various forms"
            items ={['Banking']}
          ></ProjectCards>
        </div>
      );
    } else if (this.state.activeTab === 1) {
      return (
        <div>
          <h1>This is Node</h1>
        </div>
      );
    } else if (this.state.activeTab === 2) {
      return (
        <div className="projects-grid">
          <Card shadow={5} style={{ minWidth: 450, margin: "auto" }}>
            <CardTitle
              style={{
                color: "#fff",
                height: "176px",
                background:
                  "url(https://blog.dashlane.com/wp-content/uploads/2016/02/react-logo-2.png) center / cover",
              }}
            >
              Monolith to Microservices #1
            </CardTitle>
            <CardText>
              <b>Achievements</b>
              <div>
                <li className="projects-grid">
                  {" "}
                  Re-designed the existing legacy applications interfaces used
                  for interacting with mainframe systems using Microservices{" "}
                </li>
                <li>
                  Legacy integration patterns were removed thereby providing
                  reduction of 50% in the Opex for TelstraIntroduced a new
                  microservices pipeline to the application CI/CD setup{" "}
                </li>
              </div>

              <b>Highlights</b>
              <li>
                Led the design, innovation, development and delivery of the
                solution
              </li>
              <li>
                Application metrics and log management using Elastic stack
                (Logstash, Filebeat, Kibana and Elasticsearch)
              </li>
              <li>Introduced scalability and fault tolerance to the stack</li>
            </CardText>
            <CardActions>
              <Button colored>Github</Button>
              <Button colored>LiveDemo</Button>
              <Button colored>CodePen</Button>
            </CardActions>
            <CardMenu style={{ color: "#fff" }}>
              <IconButton name="share" />
            </CardMenu>
          </Card>

          <Card shadow={5} style={{ minWidth: 450, margin: "auto" }}>
            <CardTitle
              style={{
                color: "#fff",
                height: "176px",
                background:
                  "url(https://blog.dashlane.com/wp-content/uploads/2016/02/react-logo-2.png) center / cover",
              }}
            ></CardTitle>
            <CardText>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used before final copy is available, but it may also be used to
              temporarily replace copy in a process called greeking, which
              allows designers to consider form without the meaning of the text
              influencing the design. Lorem ipsum is typically a corrupted
              version of De finibus bonorum et malorum, a first-century BCE text
              by the Roman statesman and philosopher Cicero, with words altered,
              added, and removed to make it nonsensical, improper Latin.
              Versions of the Lorem ipsum text have been used in typesetting at
              least since the 1960s, when it was popularized by advertisements
              for Letraset transfer sheets. Lorem ipsum was introduced to the
              digital world in the mid-1980s when Aldus employed it in graphic
              and word-processing templates for it
            </CardText>
            <CardActions>
              <Button colored>Github</Button>
              <Button colored>LiveDemo</Button>
              <Button colored>CodePen</Button>
            </CardActions>
            <CardMenu style={{ color: "#fff" }}>
              <IconButton name="share" />
            </CardMenu>
          </Card>

          <Card shadow={5} style={{ minWidth: 450, margin: "auto" }}>
            <CardTitle
              style={{
                color: "#fff",
                height: "176px",
                background:
                  "url(https://blog.dashlane.com/wp-content/uploads/2016/02/react-logo-2.png) center / cover",
              }}
            >
              React Project #1
            </CardTitle>
            <CardText>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used before final copy is available, but it may also be used to
              temporarily replace copy in a process called greeking, which
              allows designers to consider form without the meaning of the text
              influencing the design. Lorem ipsum is typically a corrupted
              version of De finibus bonorum et malorum, a first-century BCE text
              by the Roman statesman and philosopher Cicero, with words altered,
              added, and removed to make it nonsensical, improper Latin.
              Versions of the Lorem ipsum text have been used in typesetting at
              least since the 1960s, when it was popularized by advertisements
              for Letraset transfer sheets. Lorem ipsum was introduced to the
              digital world in the mid-1980s when Aldus employed it in graphic
              and word-processing templates for it
            </CardText>
            <CardActions>
              <Button colored>Github</Button>
              <Button colored>LiveDemo</Button>
              <Button colored>CodePen</Button>
            </CardActions>
            <CardMenu style={{ color: "#fff" }}>
              <IconButton name="share" />
            </CardMenu>
          </Card>
        </div>
      );
    } else if (this.state.activeTab === 3) {
      return (
        <div>
          <h1>This is Java</h1>
        </div>
      );
    } else if (this.state.activeTab === 4) {
      return (
        <div>
          <div className="projects-grid">
 
            <ProjectCards
              title="Integration Designer - Telecom" 
              description="Integrating Salesforce and other SaaS applications such as
              Seismic, Open Air, Sharepoint etc using multiple integration
              methods. Utilzing API Gateways, Event Streams and Messaging
              platfors for communicating with the various platforms"
              items ={['Telecom']}

            ></ProjectCards>
 
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="category-tabs">
        <Tabs
          activeTab={this.state.activeTab}
          onChange={(tabId) => this.setState({ activeTab: tabId })}
          ripple
        >
          <Tab>Banking</Tab>
          <Tab>Cloud Integration</Tab>
          <Tab>API Design</Tab>
          <Tab>Microservices</Tab>
          <Tab>Telcom</Tab>
          <Tab>Solution Design</Tab>
        </Tabs>

        <section>
          <Grid>
            <Cell col={12}>
              <div classname="content">{this.toggleCategories()}</div>
            </Cell>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Projects;
