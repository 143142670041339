import React, {Component   } from 'react';
import {Grid, Cell } from 'react-mdl';
import Education from './Education.js'
import Certification from './Certification.js'
import Experience from './Experience.js';
import Skills from './Skills.js'
 
class Resume extends Component{

    render(){
        return ( 
            <div> 
                <Grid>
                    <Cell col={4}>
                        <div style={{textAlign:'center'}}>
                            <img
                                src="https://s3-ap-southeast-2.amazonaws.com/portfoliobucket.joseph/myAvatar+(2).png"
                                alt="avatar"
                                style ={{height: '200px'}}
                          />
                            
                        </div>
                        <h2 style={{paddingTop:'2em'}}>
                            Joseph Johney
                        </h2>
                        <h4 style={{color:'grey'}}>Solution Architect</h4>
                        <hr style={{borderTop:'3px solid #833fb2', width:'50%'}}/>
                        <p>
                        Lead Engineer with SaaS integration experience, working on large scale transformation program and on wide variety of integration solutions and platforms (Apigee, AWS, Azure, Mulesoft, Salesforce)
                            <ul>
                            <li style={{type: 'square'}}>Received the Infosys 2019-20 H1 APAC excellence award for contributions to Telstra’s Digitization program </li>
                            <li style={{type: 'square'}}>Led the development team which received Telstra CIO award for best project (SIP Trunking on ASP) for two times</li>
                            <li style={{type: 'square'}}>Best contributor Award for Q4 FY 20 in Telstra Digital Adoption program</li>
                            <li style={{type: 'square'}}>Part of the team which received Telstra CTO awards (SIP Trunking - Phase 2)</li>
                            </ul>
                        Widely appreciated for the drive to provide the best solutions, stakeholder management and defect-free delivery
of critical projects
                        </p>
                        <hr style={{borderTop:'3px solid #833fb2', width:'50%'}}/>
                        <h5>Address</h5>
                        <p> Melbourne</p>
                        <h5>Phone</h5>
                        <p>(+61) 0439473163</p>
                        <h5>Email</h5>
                        <p>josephjohney16@gmail.com</p>
                        <h5>Web</h5>
                        <p>josephjohney.com</p>
                    </Cell>
                    <Cell className = "resume-right-col" col={8}>
                        <h3>Certification</h3>
                        <Certification
                            year={2022}
                            name= "AWS Solution Architect"
                            description="AWS"
                        /><Certification
                            year={2022}
                            name= "Google Cloud Certified - Cloud Digital Leader "
                            description="GCP"
                        />
                        <Certification
                            year={2020}
                            name= "Azure Fundamentals"
                            description="Microsoft Azure"
                        />
                        <Certification
                            year={2020}
                            name= "Developing APIs with Google Cloud's Apigee API Platform Specialization"
                            description="GCP- Coursera"
                        />
                       
                       
                        <Certification
                            year={2019}
                            name= "Amazon Web Services Developer Associate"
                            description="Amazon Web Services(AWS)"
                        />

 
                        <Certification
                            year={2012}
                            name= "Java Standard Edition 6 Programmer Certified Professional"
                            description="Oracle"
                        />
                        <Certification
                            year={2014}
                            name= "ITIL Foundation Certificate in Service Management"
                            description="ITIL"
                        />

                        <h2>Education</h2>

                        <Education
                            startYear={2005}
                            endYear={2009}
                            schoolName="College of Engineering, Trivandrum, Kerala University"
                            schoolDescription="Bachelor of Technology, Electronics and Communications Engineering"
                        />
                        <Education
                            startYear={2015}
                            endYear={2017}
                            schoolName="Birla Institute of Technology, Pilani, India"
                            schoolDescription="Master of Technology, Computer Systems"
                        />
                        <hr style ={{borderTop: '3px solid #e22947'}}/>
                        <Experience
                            startYear={2021}
                            jobName="HCL Australia Services"
                            jobDescription="Senior Technology Architect"
                            jobRole="Staff Augmented Role : Integration Architect"
                        />
                        <Experience
                            startYear={2020}
                            endYear={2015}
                            jobName="Infosys Technologies"
                            jobDescription="Technology Lead"
                            jobRole="Staff Augmented Role : Integration Designer"
                        />
                        <Experience
                            startYear={2011}
                            endYear={2012}
                            jobName="Infosys Technologies"
                            jobDescription="Technology Analyst"
                            jobRole=""
                        />
                        <Experience
                            startYear={2011}
                            endYear={2012}
                            jobName="Infosys Technologies"
                            jobDescription="Senior Systems Engineer"
                            jobRole=""
                            />
                        
                        <Experience
                            startYear={2009}
                            endYear={2011}
                            jobName="Infosys Technologies"
                            jobDescription="Systems Engineer"
                            jobRole=""
                        />
                        
                     <hr style ={{borderTop: '3px solid #e22947'}}/>
                     <h2>
                         Skills
                     </h2>
                     <Skills 
                        skill="Apigee API"
                        progress={90}
                    /> 
                    <Skills 
                        skill="Mulesoft API Led Connectivity"
                        progress={80}
                    /> 
                    <Skills 
                        skill="Java Microservices"
                        progress={80}
                    /> 
                    <Skills 
                        skill="javaScript"
                        progress={90}
                    /> 
                    <Skills 
                        skill="NodeJS"
                        progress={60}
                    /> 
                    <Skills 
                        skill="Java"
                        progress={90}
                    /> 
                    <Skills 
                        skill="React"
                        progress={40}
                    /> 
                    <Skills 
                        skill="HTML/CSS"
                        progress={70}
                    /> 
                    </Cell>
                </Grid>
            </div>
        )
    }
}

export default Resume;
