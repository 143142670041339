import React , {Component} from 'react';
import {Grid, Cell }from 'react-mdl'

class Certification extends Component{

    render (){
        return (
           <Grid>
               <Cell col={4}>
                    <p>
                        {this.props.year} 
                    </p>
               </Cell>
               <Cell col={8}>
                   <h5 style={{marginTop:'0px'}}>{this.props.name}</h5>
                    <p>{this.props.description}</p>
               </Cell>
           </Grid>
        );
    }
}

export default Certification;